@font-face {
  font-family: "Noto Sans";
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/NotoSans-Light.ttf") format("opentype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 400;
  font-style: normal;
  src: url("./fonts/NotoSans-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/NotoSans-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  font-style: normal;
  src: url("./fonts/NotoSans-SemiBold.ttf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@layer base {
  html {
      @apply text-black text-base;

      scrollbar-gutter: stable
  }

  a {
      @apply text-primary hover:underline;
  }

  button:focus {
      @apply outline-none;

      box-shadow: none !important;
  }

  h1 {
      @apply text-2xl; /* 24px */
      @apply font-semibold;
      @apply leading-8 tracking-normal;
  }

  h2 {
      @apply text-xl; /* 22px */
      @apply font-medium;
      @apply leading-7 tracking-normal;
  }

  h2.light {
      @apply text-lg; /* 20px */
      @apply font-normal;
      @apply leading-p140 tracking-normal;
  }

  h3 {
      @apply text-md; /* 16px */
      @apply font-semibold;
      @apply leading-p150 tracking-normal;
  }

  h4 {
      @apply text-base; /* 14 px */
      @apply font-semibold;
      @apply leading-p150 tracking-normal;
  }

  h4.light {
      @apply text-sm; /* 13 px */
      @apply font-medium;
      @apply leading-p140 tracking-normal;
  }

  .font-bold {
      @apply antialiased;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"] {
      appearance: none;
  }

  input[type="number"] {
      appearance: textfield;
  }

  .flex-center {
      @apply flex;
      @apply justify-center;
      @apply items-center;
  }
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

@keyframes progress {
  0% {
      stroke-dasharray: 0 100;
  }
}

@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

input:focus {
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}

.nowrap {
  text-wrap: nowrap;
}

.break-anywhere {
  overflow-wrap: anywhere;
}

